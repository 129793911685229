

/*============HOME-PAGE-INFO============*/
.home-page-info {
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px 20px;
  flex: 1 0 auto;
}

.home-page-info > * {
  flex: 1 0 auto;
}

.home-page-info-card {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40%;
  min-height: 120px;

  background: var(--card-color);
  margin: 0px 1%;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 20px;
  box-shadow: var(--box-shadow-color) 0px 3px 8px;
  /*color: var(--text-color);*/
  color: white;
}

.sub-home-page-info-card{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.home-page-info-card2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  min-height: 250px;

  background: var(--sidebar-color);
  margin: 20px 1%;
  border-radius: 6px;
  color: var(--text-color);
  box-shadow: var(--box-shadow-color) 0px 3px 8px;
  transition: var(--tran-03);
}

.home-page-info-card2:hover {
  transform: scale(1.05);
  /*box-shadow: var(--gradient) 0px 3px 8px;*/
  box-shadow: 0 0 10px 5px var(--card-color);
}
.home-page-info-card2:hover:not(:hover) {
  box-shadow: var(--gradient) 0px 5px 15px;
  transform: scale(1.05);
}
.icon-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient);
  height: 100%;

  max-width: 500px;
  border-radius: 6px 0 0 6px;
}

.column-card-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.column-card-content span {
  margin-top: 10px;
}

.column-card-content a {
  text-align: right;
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

.box-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}

.home-page-info-specjalizacja {
  flex-basis: 90%;


  border-radius: 6px;
  margin: 20px 1%;

  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-specjalization-img {
  max-width: 80px;
  height: auto;
}

.home-page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-basis: 100%;
  margin: 5px 0;
  color: var(--text-color);
 /* padding-bottom: 6px;*/
}
.home-page-title h1 {
  font-size: 34px;
}

span.neutral-text {
  font-size: 20px;
}
span.bookmark-text {
  font-weight: bold;
  color: var(--clinic-color);
}

.icon-img {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
}

.title-logo-img {
  max-width: 100px;
  margin-right: 15px;
}

.home-page-info-card3 {
  display: flex;
  justify-content: center;
  align-items: center;
 
  
  box-shadow: var(--box-shadow-color) 0px 3px 8px;
  background: var(--sidebar-color);
  margin: 20px 1%;
  border-radius: 6px;
  color: var(--text-color);
  transition: var(--tran-03);
}
.home-page-info-card3-parent{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
  
}
.home-page-info-card4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  border-radius: 6px;
  color: var(--text-color);
  transition: var(--tran-03);
 
}
.card4-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: center;

  width: 100%;
}
.card4-item-box {
  display: flex;
  margin: 0 1%;
  margin-top: 80px;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: column;
  min-width: 260px;
  background: var(--sidebar-color);
  width: 20%;
  border-radius: 6px;
  box-shadow: var(--box-shadow-color) 0px 3px 8px;
}
.card4-item-box-services{
  display: flex;
  margin: 0px 1%;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: column;
  min-width: 420px;
  max-width: 800px;
  background: var(--sidebar-color);
  width: 20%;
  border-radius: 6px;
 border: 1px solid var(--item-border);
}

.card4-items-container > * {
  flex: 1 0 auto;
}
.card4-item-box-text {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card4-item-box-text-services {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
 
}

.card4-item-box-text h5 {
  margin-bottom: 10px;
  margin-right: 30px;
}

.card4-item-box-text-position {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card4-item-box-text-position-services {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: var(--tran-03);
  margin: 5px 0;
  padding: 5px 0;
  border-radius: 6px;
}
.card4-item-box-text-position-services.sub-link{
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: var(--tran-03);
  margin: 1px 0;

  padding: 5px 0;
  padding-left: 25px;
  border-radius: 6px;
}
.card4-item-box-text-position-services:hover {
  background-color: var(--clinic-color);
  color: white;
  transition: var(--tran-03);
  transform: scale(1.05);
  cursor: pointer;
}
.card4-item-box-text-position .card4-item-box-text-position-merger .card4-item-box-text-position-colum,
.card4-item-box-text-position-services .card4-item-box-text-position-merger .card4-item-box-text-position-colum
{
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
}
.card4-item-box-text-position-merger{
  display: flex;
  flex-direction: row;
}
.card4-item-box-text-position .arrow-icon-div .arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
 
  font-size: 25px;
  border-radius: 50px;
  transition: var(--tran-03);

 
}
.card4-item-box-text-position .circle-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.card4-item-box-text-position .arrow-icon-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.card4-item-box-text-position .arrow-icon-div .arrow-icon:hover {
  transform: scale(1.5);
  cursor: pointer;
  background: var(--clinic-color);
  color: var(--sidebar-color);
}
.arrow-icon-to-center{
  display: flex;
  justify-content: center;
 
}
.arrow-icon-listing{
  margin-top: -2px;
}
.card4-item-box-text .card-4-item-box-more-div {
  text-align: right;
  padding-top: 10px;
}
.home-page-info-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  transition: var(--tran-03);
}
.card4_img_icon {
  max-width: 120px;
  position: relative;
  top: -60px;
  margin-bottom: -50px;
}
.card4_img{
  max-width: 100%;
 height: auto;
  padding-bottom: 20px;
}
.card4-item-title{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  
  background: var(--sidebar-color);
 
  position: relative;
  width: 100%;
  
  
  border-radius: 50%;
  bottom: -10px;
}
.card4-item-title h1{
  margin-top: -10px;
}
.card4-item-box-text-position-merger h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card4-h4 {
  margin-bottom: 50px;
}

.icon-img-container3 {
  display: flex;
  
  

  border-radius: 6px 0 0 6px;
}
.icon-img2 {

 
  
  border-radius: 6px 0 0 6px;
}

.icon-img-container3.mirror,
.icon-img2.mirror {
  border-radius: 0 6px 6px 0;
}

.icon-img-container3.mirror.mobile {
  display: none;
}
.bracket-img {
  width: 100%;
  height: auto;
  flex-basis: 100;
  position: relative;
  top: -10px;
  margin-bottom: 0px;
  object-fit: cover;
}

.click-for-more-link-text{
  font-style: italic;
  text-decoration: underline;
  /*color: var(--text-color);*/
}

.home-free-text{
  margin: 0px 1%;
  margin-bottom: 0px;
}

.underscore{
  text-decoration: underline;
}