.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar .nav-links li a .link_name {
  font-weight: 500;
}

.sidebar .nav-links li a:hover .link_name {
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 0px;
  margin-top: -10px;
  border-radius: 6px;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.8;
}
.sidebar .nav-links li.showMenu .sub-menu a {
  padding-left: 10px;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
  transform: scale(1.1);
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*============DARK MODE============*/
/*body.dark .sidebar .nav-links li .sub-menu .link_name {
  display: none;
}*/

/*============CLOSE SIDEBAR============*/
.sidebar.close .nav-links li .sub-menu .link_name {
  opacity: 1;
  display: block;
}

.sidebar.close .nav-links li .iocn-link {
  display: block;
}

.sidebar.close .nav-links i.arrow {
  display: none;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 6px;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li .sub-menu {
  background: var(--primary-color);
}

.sidebar.close .nav-links li .sub-menu .link_name {
  opacity: 1;
  display: block;
}

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar li i.sub-link {
  line-height: 0;
  min-width: 25px;
  font-size: 20px;
  cursor: pointer;
}

.sidebar li.showMenu i.sub-link {
  line-height: 0;
  min-width: 25px;
  font-size: 20px;
  cursor: pointer;
}

.sidebar .nav-links .sub-menu li a{
  overflow-wrap: break-word;
  white-space: nowrap;
}

.sidebar .nav-links li.showMenu a {
  overflow-wrap: break-word;
  white-space: normal;
}

.sidebar .nav-links li.showMenu .sub-menu a i {
  font-size: 16px;
}
