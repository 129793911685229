.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  color: var(--text-color);
  z-index: 100;
  transition: var(--tran-03);
}

.sidebar li i {
  line-height: 50px;
  min-width: 60px;
  font-size: 20px;
  cursor: pointer;
}

.sidebar .nav-links {
  height: 100%;
  padding: 0px 0 20px 0;
  /*overflow-y: auto;*/
}
/*
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}*/
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  border-radius: 6px;
  transition: var(--tran-03);
}
.sidebar .nav-links li:hover{
  background: var(--primary-color);
  color: var(--sidebar-color);
  transition: var(--tran-03);
  border-radius: 6px;
}


.sidebar .nav-links li:hover a{
  color: var(--sidebar-color);
  border-radius: 6px;
  transition: var(--tran-03);
}

.sidebar .nav-links li:hover a {
  color: var(--sidebar-color);
  transition: var(--tran-03);
}

.sidebar header,
.image-text {
  display: flex;
  align-items: center;
}

.sidebar header i {
  color: var(--sidebar-color);
  transition: var(--tran-03);
}

.sidebar .image-text img {
  width: 40px;
  height: auto;
  border-radius: 6px;
  transition: var(--tran-03);
}

.sidebar {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  white-space: nowrap;
  opacity: 1;
}

.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .image-text .header-text {
  display: flex;
  flex-direction: column;
}

.header-text .name {
  font-weight: 600;
}

.header-text .profession {
  margin-top: -2px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-03);
}

.sidebar .menu {
  margin-top: 35px;
  overflow: hidden;
}

.sidebar header {
  position: relative;
}

.sidebar .no-link-li {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: var(--tran-03);
}
.sidebar .nav-link {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: var(--tran-03);
}
.sidebar .nav-link:hover {
  background: var(--primary-color);
  color: var(--sidebar-color);
  transition: var(--tran-03);
}

.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
  padding: 15px 0;
}

.sidebar li a,
.sidebar li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 6px;
  color: var(--text-color);
  transition: var(--tran-03);
}
.sidebar li a:hover,
.sidebar li a:hover{
  color: var(--sidebar-color);
  transition: var(--tran-03);
}

.sidebar .search-box {
  background: var(--primary-color-light);
  border-radius: 6px;
  margin-bottom: 20px;
}

.search-box input {
  height: 100%;
  width: 100%;
  background: var(--primary-color-light);
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  transition: var(--tran-03);
}
/*
.sidebar .no-link-li a:hover {
  background: var(--primary-color);
  color: var(--sidebar-color);
}*/

.sidebar .menu-bar {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-bar .mode {
  position: relative;
  border-radius: 6px;
  background: var(--primary-color-light);
  transition: var(--tran-03);
}

.menu-bar .mode i {
  position: absolute;
}

.menu-bar .mode .moon-sun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
  transition: var(--tran-03);
}

.menu-bar .mode i.sun {
  opacity: 0;
}

.menu-bar .mode .toggle-switch {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 60px;
  cursor: pointer;
  border-radius: 6px;
  background: var(--primary-color-light);
  transition: var(--tran-03);
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  border-radius: 25px;
  background: var(--toggle-color);
  transition: var(--tran-03);
}

.switch::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background: var(--sidebar-color);
  transition: var(--tran-03);
}


/*============DARK MODE============*/

body.dark .switch::before {
  left: 24px;
}

body.dark .sidebar header .toggle,
body.dark .sidebar input {
  color: var(--text-color);
}

body.dark .menu-bar .mode i.sun {
  opacity: 1;
}

body.dark .menu-bar .mode i.moon {
  opacity: 0;
}

body.dark .sidebar .nav-links li:hover{
  background: var(--primary-color);
  color: var(--text-color);
  border-radius: 6px;
}

body.dark .sidebar .nav-links li:hover a {
 
  color: var(--text-color);
  border-radius: 6px;
}

body.dark .sidebar .nav-link {
  color: var(--text-color);
}

/*============CLOSE SIDEBAR============*/
.sidebar.close header .toggle {
  transform: translateY(-50%);
}

.sidebar.close {
  width: 88px;
}

.sidebar.close .text {
  opacity: 0;
  transition: var(--tran-03);
}

.sidebar.close .nav-links {
  overflow-y: visible;
}
.sidebar.close .menu-bar .menu {
  overflow: visible;
}

.sidebar.close li .link_name{
  opacity: 0;
  transition: var(--tran-03);
}

.hidden-li{
  list-style: none;
  transition: var(--tran-03);
  color: var(--sidebar-color);
  
}