/*============SLIDES IMG============*/

.main-baner {
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;

  overflow: hidden;
}

.slides {
  width: 500%;

  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  max-width: 100%;
  height: auto;
}

.miniclinic-baner-img{
  width: 100%;
  height: auto;
}

/*Manual Navigation*/

.navigation-manual {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: -6px;
  height: 20px;
}

.manual-btn {
  border: 1.5px solid var(--text-color);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  margin-bottom: 40px;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: var(--text-color);
}

#radio1:checked ~ .first {
  margin-left: 0;
}
#radio2:checked ~ .first {
  margin-left: -20%;
}
#radio3:checked ~ .first {
  margin-left: -40%;
}
#radio4:checked ~ .first {
  margin-left: -60%;
}

/*Automatic navigation*/

.navigation-auto {
  position: relative;
  width: 100vw;

  bottom: 0;

  margin: auto;
  display: flex;
  justify-content: center;
}
.navigation-auto div {
  border: 3px solid var(--text-color);
  padding: 5px;
  border-radius: 10px;

  transition: 1s;
}

.auto-btn:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked .auto-btn1 {
  background: var(--text-color);
  color: red;
}
#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: var(--text-color);
}
#radio3:checked ~ .navigation-auto .auto-btn3 {
  background: var(--text-color);
}
#radio4:checked ~ .navigation-auto .auto-btn4 {
  background: var(--text-color);
}

#radio1:checked .manual-btn1 {
  background: var(--text-color);
}
