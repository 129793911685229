
@font-face {
  font-family: 'Poppins'; 
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; 
  font-style: normal; 
  font-display: fallback;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('../fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400; 
  font-style: italic; 
  font-display: fallback;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600; 
  font-style: normal; 
  font-display: fallback;
}

@font-face {
  font-family: 'Poppins'; 
  src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; 
  font-style: italic; 
  font-display: fallback;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=fallback'); */
/*Poppins:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');*/
* {
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background: var(--body-color);
  transition: var(--tran-03);
}

:root {
  --body-color: #ffffff;
  --sidebar-color: rgb(255, 255, 255);
  --primary-color: #3678BF;
  --primary-color-light: #ffffff;
  --primary-color-alt:  #ffffff;
  --toggle-color: #ddd;
  --text-color: #535353;
  --box-shadow-color: rgba(0, 0, 0, 0.24);
  --clinic-color: #3678BF;
  --card-color: #3678BF;
  --item-border: rgb(226, 226, 226);
  --gradient: linear-gradient(
    145deg,
    rgba(73, 177, 228, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

/*============DARK MODE============*/
body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --primary-color-alt: #242526;
  --toggle-color: #fff;
  --text-color: rgb(197, 197, 197);
  --box-shadow-color: rgb(38, 42, 53);
  --clinic-color: rgb(101, 144, 209);
  --card-color: rgb(30, 60, 92);
  --item-border: rgb(65, 65, 65);
  --gradient: linear-gradient(
    145deg,
    rgb(11, 97, 168) 0%,
    rgb(0, 161, 226) 100%
  );
}

.parent-page-content {
  position: absolute;
  top: 0;
  left: 250px;
  height: 100vh;
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  transition: var(--tran-03);
}

.parent-page-content .text {
  font-weight: 500;
  color: var(--text-color);
  padding: 8px 40px;
}

/*============CLOSE SIDEBAR============*/
.sidebar.close ~ .parent-page-content {
  left: 88px;
  height: 100vh;
  width: calc(100% - 88px);
}

a:-webkit-any-link {
  color: var(--text-color);
  text-decoration: none;
}

a:-webkit-any-link:hover {
  
  text-decoration: none;
}


h1{
  padding-bottom: 10px;
  font-size: 26px;
}

h2{font-size: 22px;}


.internal-link {
  text-decoration: underline;
  font-style: italic;
  font-weight: bold;
  color: var(--clinic-color);
}
/*
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
/* background: transparent; /* Optional: just make scrollbar invisible */
/*}
/* Optional: show position indicator in red */
/*::-webkit-scrollbar-thumb {
  background: #ff0000;
}*/
